<template>
  <div class="guaranteePhone">
    <div class="pageTitle">
      保函验真结果
    </div>
    <div class="modal">
      <div class="black">
        <img
          v-if="iconImg"
          src="../../assets/image/veritfy-icon1.cf6a0b46.png"
          alt=""
        >
        <img
          v-else
          src="../../assets/image/veritfy-icon2.437579bb.png"
          alt=""
        >
        <div class="title">
          {{ iconImg ? '核验成功' : '核验失败' }}
        </div>
      </div>
      <ul v-if="iconImg">
        <li><span class="label">保函编号</span><span class="value">{{ form.orderId }}</span></li>
        <li><span class="label">被保证人</span><span class="value">{{ form.name }}</span></li>
        <li><span class="label">保函金额</span><span class="value">{{ throundsHandle(form.sumAmount) }}元</span></li>
        <li><span class="label">保函有效期</span><span class="value">{{ form.validityPeriod }}</span></li>
        <li><span class="label">保函状态</span><span class="value">{{ insureStatus(form.stauts) }}</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { insureVerification } from '@/api/guaranteeTruth.js';
import { dictDetail } from '@/api/performance.js';

export default {
  data() {
    return {
      iconImg: true,
      form: {},
      dictStatus: null,
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    throundsHandle (value) {
      if (value) {
        let a = value.toString().split('.')[0]
        let b = value.toString().split('.')[1]
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ( b ? '.' + b : '')
      } else {
        return value
      }
    },
    refresh() {
      dictDetail({ dictName: 'insureStatus' }).then(res => {
        this.dictStatus = res.data.data;
      });
      insureVerification({ guaranteeNumber: this.$route.query.orderNo }).then(res => {
        if (res.data.status === 500) {
          this.iconImg = false;
        } else {
          this.iconImg = true;
          this.form = res.data.data;
        }
      });
    },
    insureStatus(status) {
      if (this.dictStatus) {
        for (const i in this.dictStatus) {
          if (this.dictStatus[i].value === status) {
            return this.dictStatus[i].label;
          }
        }
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pageTitle {
  width: 100%;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  box-shadow: 0 0 5px rgba(0,0,0, 0.1);
  text-align: center;
}
  .modal {
    // height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .black {
      width: 90vw;
      background-color: #f2f2f2;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
    }
    img {
      width: 100px;
      height: 100px;
    }
    .title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: 10px 0 20px;
    }
    ul {
      border: 1px solid #E5E5E5;
      border-bottom: none;
      li {
        display: flex;
        border-bottom: 1px solid #E5E5E5;
        .label {
          width: 30vw;
          height: 32px;
          background: #F9F9F9;
          line-height: 32px;
          text-align: right;
          padding-right: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          border-right: 1px solid #E5E5E5;
        }
        .value {
          width: 60vw;
          height: 32px;
          background: #FFFFFF;
          line-height: 32px;
          padding-left: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

</style>
